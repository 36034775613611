<script setup>
import { useVModel } from '@vueuse/core';

import isMobile from 'is-mobile';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';
import Multiselect from 'vue-multiselect';
import useForm from '@/composables/useForm';

import { computed, nextTick, onMounted, ref } from '#imports';
import { Field as VeeField } from 'vee-validate';
import CIcon from '@/components/common/CIcon.vue';

const formComposable = useForm();

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  hint: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String, Number, Object],
    default: '',
  },
  name: {
    required: true,
    type: String,
  },
  options: {
    required: true,
    type: Array,
  },
  isDisabled: {
    required: false,
    type: Boolean,
    default: false,
  },
  isClearButton: {
    type: Boolean,
    default: false,
  },
  showErrors: {
    type: Boolean,
    default: false,
  },
  trackBy: {
    required: false,
    type: String,
    default: '',
  },
  trackDisplayedBy: {
    required: false,
    type: String,
    default: '',
  },
  searchable: {
    required: false,
    type: Boolean,
    default: false,
  },
  preselectFirst: {
    required: false,
    type: Boolean,
    default: false,
  },
  placeholder: {
    required: false,
    type: String,
    default: '',
  },
  openDirection: {
    required: false,
    type: String,
    default: '',
  },
  allowEmpty: {
    required: false,
    type: Boolean,
    default: false,
  },
  showLabels: {
    required: false,
    type: Boolean,
    default: false,
  },
});

//<editor-fold desc="Base">
const emit = defineEmits(['update:modelValue', 'openSelect', 'closeSelect']);

const isFocused = ref(false);
const selectItem = ref(null);

const localValue = useVModel(props, 'modelValue', emit);

function openSelect() {
  selectItem.value.activate(); // метод компонента multiselect
}

function openHandler() {
  emit('openSelect');

  isFocused.value = true;

  nextTick(() => {
    initScrollbar();
  });
}

function closeHandler() {
  isFocused.value = false;

  emit('closeSelect');
}

function clear() {
  emit('update:modelValue', null);
}

//</editor-fold>

//<editor-fold desc="Custom scrollbar">
const contentWrapperItem = ref(null);
const simplebarInstance = ref(null);

onMounted(() => {
  nextTick(() => {
    contentWrapperItem.value = selectItem.value.$el.querySelector(
      '.multiselect__content-wrapper',
    );
  });
});

function initScrollbar() {
  if (isMobile()) return;
  if (simplebarInstance.value) return;

  simplebarInstance.value = new SimpleBar(contentWrapperItem.value, {});
}

//</editor-fold>

//<editor-fold desc="Availability">
const randomSelectId = ref();
const randomErrorId = ref();
const ariaDescribedby = ref();

onMounted(() => {
  randomSelectId.value = formComposable.getRandomCode('select');
  randomErrorId.value = formComposable.getRandomCode('error');
  ariaDescribedby.value = randomErrorId.value;
});

const ariaExpanded = computed(() => {
  return isFocused.value ? 'true' : 'false';
});
//</editor-fold>
</script>

<template>
  <VeeField
    v-slot="{ field, errorMessage }"
    :model-value="localValue"
    :name="props.name"
    as="div"
  >
    <div
      class="ui-select"
      :class="[
        {
          'ui-select--active': isFocused,
          'ui-select--has-value': modelValue,
          'ui-select--disabled': isDisabled,
          'ui-select--error': !!errorMessage,
          'ui-select--inner-indent': showErrors,
          'ui-select--is-clear': isClearButton,
        },
      ]"
      @mousedown="openSelect"
    >
      <div v-if="props.label" :class="`ui-select__label`">
        <span :class="`ui-select__font ui-select__font--label`">
          {{ props.label }}
        </span>
      </div>

      <div v-if="props.hint" :class="`ui-select__hint`">
        <span :class="`ui-select__font ui-select__font--hint`">
          {{ props.hint }}
        </span>
      </div>

      <multiselect
        :id="randomSelectId"
        ref="selectItem"
        v-model="localValue"
        v-bind="field"
        :class="[
          `ui-select__field theme-scrollbar theme-scrollbar--size--small`,
          {
            'multiselect--custom--option': $slots.option,
            'multiselect--custom--single': $slots.single,
          },
        ]"
        :options="options"
        :name="props.name"
        :label="props.trackDisplayedBy"
        :track-by="props.trackBy"
        :searchable="props.searchable"
        :allow-empty="props.allowEmpty"
        :show-labels="props.showLabels"
        :placeholder="props.placeholder"
        :open-direction="props.openDirection"
        :preselect-first="props.preselectFirst"
        :aria-expanded="ariaExpanded"
        :aria-describedby="ariaDescribedby"
        :aria-invalid="Boolean(props.showErrors && errorMessage)"
        @open="openHandler"
        @close="closeHandler"
      >
        <template #caret="{ toggle }">
          <button
            v-if="isClearButton"
            :class="[`ui-select__button ui-select__button--clear`]"
            type="button"
            aria-label="Стереть"
            @mousedown.prevent.stop="clear"
          >
            <CIcon
              name="select-clear"
              :class="[`ui-select__icon ui-select__icon--clear`]"
            />
          </button>

          <button
            :class="[
              `ui-select__button ui-select__button--arrow`,
              {
                'ui-select__button--arrow--reversed': isFocused,
              },
            ]"
            type="button"
            aria-label="Показать список"
            @mousedown.prevent.stop="toggle"
          >
            <CIcon
              name="select-arrow"
              :class="[`ui-select__icon ui-select__icon--arrow`]"
            />
          </button>
        </template>

        <template v-if="$slots.option" #option="{ option }">
          <slot name="option" :option="option"></slot>
        </template>

        <template v-if="$slots.single" #singleLabel="{ option }">
          <slot name="single" :option="option"></slot>
        </template>

        <template #noResult>
          <span :class="`ui-select__element ui-select__element--disabled`">
            Поиск не дал результатов
          </span>
        </template>

        <template #noOptions>
          <span :class="`ui-select__element ui-select__element--disabled`">
            Список пуст
          </span>
        </template>
      </multiselect>

      <span
        v-show="showErrors && errorMessage"
        :class="[`ui-select__footer`]"
        :title="errorMessage"
      >
        <span
          :id="randomErrorId"
          aria-live="assertive"
          :class="[`ui-select__font ui-select__font--error`]"
          v-html="errorMessage"
        />
      </span>
    </div>
  </VeeField>
</template>

<style lang="scss">
/* stylelint-disable */
.multiselect {
  $parent: &;

  min-height: auto;
  font-size: 1em;

  &__icon {
    &--tick {
      width: em(13);
      height: em(9);
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  &__input {
    padding: 0;
    margin-bottom: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
  }

  &__placeholder {
  }

  &__select {
    top: calc(50% + #{em(2)});
    width: em(40);
    height: em(40);
    transform: translateY(-50%);
  }

  &__tags {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: auto;
    font-size: inherit;
    cursor: pointer;
    background-color: transparent;
    border: none;
    transition: background-color 0.3s;
  }

  &__content-wrapper {
    position: absolute !important;
    top: em(55);
    right: -1px;
    left: -1px;
    z-index: 3;
    width: auto;
    overflow: hidden auto;
    background-color: transparent;
  }

  &__content {
    width: 100%;
  }

  &__single,
  &__option {
    padding: 0;
    margin: 0;
    font-size: inherit;
    white-space: unset;
    background: transparent;
  }

  &__single {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: color 0.3s;
  }

  &__option {
    display: block;
    width: 100%;
    min-height: auto;
    padding: 0;
    overflow: hidden;
    color: inherit;
    background-color: transparent;
    transition: background-color 0.3s;
    cursor: pointer;

    @include hover {
      #{$parent} {
        &__icon {
          &--tick {
            opacity: 1;
          }
        }
      }
    }
  }

  &__element {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;

    &--disabled {
      pointer-events: none;
      touch-action: none;
    }
  }

  &__option--selected,
  &__option--selected.multiselect__option--highlight {
    background-color: transparent;
  }

  &--disabled {
    opacity: 1;
  }

  &--active {
    #{$parent} {
      &__select {
        transform: translateY(-50%) scale(-1);
      }
    }
  }
}

/* stylelint-enable */
</style>

<style lang="scss">
.ui-select {
  $parent: &;
  $font-family: $font-family-default;
  $color-basis: $color-black-100;
  $color-text: $color-black-100;
  $color-error: $color-red-100;
  $color-placeholder: $color-black-100;
  $color-label: $color-black-130;
  $color-label-active: $color-black-150;
  $color-hint: $color-black-120;
  $color-bg-list: $color-white;
  $color-border: $color-grey-200;
  $color-border-active: $color-grey-300;
  $color-border-list: $color-grey-200;
  $color-shadow-list: transparent;
  $color-bg: $color-white;
  $color-bg-active-item-list: $color-grey-115;
  $color-disabled: $color-grey-300;
  $color-bg-disabled: $color-white;
  $color-hover: $color-black-100;
  $inner-indent: 21;

  --max-height: #{em(200)};

  position: relative;
  color: $color-basis;
  font-family: $font-family;

  & .multiselect {
    &__content {
      max-height: var(--max-height);
      padding: em(8) 0;
    }

    &__placeholder {
      font-size: em(16);
      color: $color-placeholder;
    }

    &__content-wrapper {
      @include hide-scroll(); // если задается border то подключить миксин

      top: calc(100% + 0px);
      max-height: var(--max-height) !important;
      background-color: $color-bg-list;
      border-radius: 0;
      border: 1px solid $color-border-list;
      //box-shadow: 0 em(4) em(14) 0 rgba($color-shadow-list, 0.25);
    }

    &__input {
      font-size: em(16);
      color: $color-text;
      text-transform: none;
      border: 0;
    }

    &__tags {
      padding: em(27) em(50) em(2) em(20);

      @include media-breakpoint-down(lg) {
        padding: em(20) em(50) em(2) em(15);
      }

      @include media-breakpoint-down(sm) {
        padding: em(27) em(50) em(2) em(20);
      }
    }

    &__select {
      top: calc(50% + #{em(2)});
      width: em(40);
      height: em(40);
    }

    &__single {
      font-size: em(14);
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      @include hover {
      }

      @include media-breakpoint-down(lg) {
        font-size: em(10);
      }

      @include media-breakpoint-down(sm) {
        font-size: em(14);
      }
    }

    &__option {
      //display: flex; // стили что бы переносился текст
      //align-items: center;
      //justify-content: space-between;
      height: 100%;
      padding: em(16, 14) em(20, 14);
      font-size: em(14);
      text-transform: uppercase;
      letter-spacing: 0.2em;
      color: $color-basis;
      border-left: em(2) solid transparent;
      transition:
        background-color 0.3s,
        color 0.3s,
        border-color 0.3s;

      @include text-overflow; // что бы переносился текст убрать

      @include hover {
        border-color: $color-blue-300;
        background-color: $color-bg-active-item-list;
      }

      @include media-breakpoint-down(lg) {
        font-size: em(10);
        padding: em(10, 10) em(15, 10);
      }

      @include media-breakpoint-down(sm) {
        padding: em(16, 14) em(20, 14);
        font-size: em(14);
      }

      &--selected {
        border-color: $color-blue-300;
        background-color: $color-bg-active-item-list;

        #{$parent}__icon--tick {
          opacity: 1;
        }
      }
    }

    &__element {
      &::after {
        content: none;
      }

      & + & {
        margin-top: em(5);
      }
    }
  }

  @mixin active-styles {
    #{$parent} {
      &__field {
        border-color: $color-border-active;
      }

      &__label {
        transform: translateY(0);
        pointer-events: auto;
        touch-action: auto;
        color: $color-label-active;
      }

      &__hint {
        opacity: 1;
      }

      &__font {
        &--label {
          font-size: em(10);

          @include media-breakpoint-down(lg) {
            font-size: em(8);
          }

          @include media-breakpoint-down(sm) {
            font-size: em(10);
          }
        }
      }
    }

    .multiselect__single {
    }
  }

  &__font {
    &--empty {
      font-size: em(14);
      line-height: 1;
    }

    &--label {
      font-size: em(14);
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      transition: font-size 0.3s;

      @include media-breakpoint-down(lg) {
        font-size: em(10);
      }

      @include media-breakpoint-down(sm) {
        font-size: em(14);
      }
    }

    &--hint {
      @include text-overflow;

      font-size: em(12);
      line-height: 1;
      letter-spacing: 0.05em;
      transition: font-size 0.3s;

      @include media-breakpoint-down(lg) {
        font-size: em(10);
      }

      @include media-breakpoint-down(sm) {
        font-size: em(12);
      }
    }

    &--error {
      @include text-overflow;

      font-size: em(10);
      font-weight: 400;
      line-height: 1.2;
      color: $color-error;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      @include media-breakpoint-down(lg) {
        font-size: em(8);
      }

      @include media-breakpoint-down(sm) {
        font-size: em(10);
      }
    }
  }

  &__icon {
    &--arrow {
      flex-shrink: 0;
      width: 100%;
      height: 100%;
    }

    &--info {
      width: em(12);
      height: em(12);
      margin-left: em(4);
      cursor: pointer;
    }

    &--tick {
      display: none;
    }

    &--clear {
      flex-shrink: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__body {
    position: relative;
    display: block;
  }

  &__footer {
    position: absolute;
    bottom: em(2);
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
  }

  &__element {
    &--disabled {
      width: 100%;
      pointer-events: none;
      touch-action: none;
    }
  }

  &__field {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: em(59);
    margin: 0;
    color: inherit;
    background: $color-bg;
    border: none;
    border-bottom: 1px solid $color-border;
    border-radius: 0;
    transition: all 0.3s;

    @include media-breakpoint-down(lg) {
      height: em(43);
    }

    @include media-breakpoint-down(sm) {
      height: em(59);
    }

    &:focus:not(:active) {
      @include active-styles;
    }

    &:active {
      #{$parent} {
      }
    }

    @include hover {
      border-color: $color-border-active;

      #{$parent} {
      }

      .multiselect {
        &__single {
        }
      }
    }
  }

  &__option {
    display: block;
  }

  &__label {
    position: absolute;
    cursor: auto;
    z-index: 1;
    top: em(6);
    left: em(21);
    display: block;
    transform: translateY(em(14));
    transition: all 0.3s;
    color: $color-label;
    pointer-events: none;
    touch-action: none;

    @include media-breakpoint-down(lg) {
      top: 0;
      left: em(16);
    }

    @include media-breakpoint-down(sm) {
      top: em(6);
      left: em(21);
    }
  }

  &__hint {
    position: absolute;
    cursor: auto;
    overflow: hidden;
    z-index: 1;
    top: em(30);
    left: em(21);
    right: em(75);
    display: block;
    transition: opacity 0.3s;
    color: $color-hint;
    pointer-events: none;
    touch-action: none;
    opacity: 0;

    @include media-breakpoint-down(lg) {
      top: em(15);
      left: em(16);
    }

    @include media-breakpoint-down(sm) {
      top: em(24);
      left: em(21);
    }
  }

  &__append {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: flex;
    line-height: 1;
    transform: translateY(-50%);

    &--before {
      left: em(10);
    }
  }

  &__button {
    &--arrow {
      position: absolute;
      top: auto;
      right: em(20);
      bottom: 50%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: em(24);
      height: em(24);
      color: $color-text;
      border-radius: 0;
      transition: all 0.3s;
      transform: translateY(50%);
      will-change: transform;

      &--reversed {
        top: auto;
        bottom: 50%;
        transform: translateY(50%) scale(-1);
      }

      @include media-breakpoint-down(lg) {
        width: em(17);
        height: em(17);
        right: em(15);
      }

      @include media-breakpoint-down(sm) {
        width: em(24);
        height: em(24);
        right: em(22);
      }
    }

    &--clear {
      position: absolute;
      top: auto;
      right: em(55);
      bottom: 50%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      transition: all 0.3s;
      transform: translateY(50%);
      will-change: transform;
      width: em(24);
      height: em(24);
      color: $color-placeholder;
      opacity: 0;
      visibility: hidden;

      @include media-breakpoint-down(lg) {
        width: em(17);
        height: em(17);
        right: em(40);
      }

      @include media-breakpoint-down(sm) {
        width: em(24);
        height: em(24);
        right: em(55);
      }

      @include hover {
      }
    }
  }

  &--before .multiselect__tags {
    padding-left: em(40);
  }

  &--overflowing .multiselect__content {
    padding-right: em(15);
  }

  &--active {
    @include active-styles;
  }

  &--has-value {
    #{$parent} {
      &__field {
        border-color: $color-border-active;
      }

      &__label {
        transform: translateY(0);
        pointer-events: auto;
        touch-action: auto;
        color: $color-label-active;
      }

      &__hint {
        opacity: 1;
      }

      &__font {
        &--label {
          font-size: em(10);

          @include media-breakpoint-down(lg) {
            font-size: em(8);
          }

          @include media-breakpoint-down(sm) {
            font-size: em(10);
          }
        }
      }
    }

    .multiselect__single {
    }

    #{$parent} {
      &__button {
        &--clear {
          opacity: 1;
          visibility: visible;
        }
      }

      &__hint {
        opacity: 0;
      }
    }

    &#{$parent}--disabled {
      #{$parent} {
        &__button {
          &--clear {
            opacity: 0;
          }
        }
      }
    }
  }

  &--inner-indent {
    padding-bottom: em($inner-indent);

    @include media-breakpoint-down(lg) {
      padding-bottom: em(15);
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: em($inner-indent);
    }
  }

  &--is-clear {
    & .multiselect {
      &__tags {
        padding-right: em(75);
      }
    }
  }

  &--error {
    #{$parent} {
      &__font--error {
        color: $color-error;
      }

      &__field {
        border-color: $color-error;
      }
    }
  }

  &--disabled {
    pointer-events: none;
    touch-action: none;
    cursor: default;

    #{$parent} {
      &__field {
        background: $color-bg-disabled;
        color: $color-disabled;
        border-color: $color-border;
      }

      &__button {
        &--arrow {
          opacity: 0.2;
        }

        &--clear {
          opacity: 0;
        }
      }
    }
  }
}
</style>
